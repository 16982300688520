import { BasePunishActionBuilder } from "@/includes/logic/Modules/PunishActions/PunishActionBuilder";

import { IWizardLikeListItem } from "piramis-base-components/src/components/WizardLikeSelect/types";

import VueI18n from "vue-i18n";

export function createListItem(key: string, builder: BasePunishActionBuilder, i18n: VueI18n): IWizardLikeListItem {
    return {
      key: key,
      title: i18n.t(builder.actionTitleKey()).toString(),
      helpMessage: i18n.te(builder.actionHelpMessageKey())? i18n.t(builder.actionHelpMessageKey()).toString() : null,
      icon: builder.icon,
    }
}
