import { BasePunishActionBuilder } from "./PunishActionBuilder";
import { BanActionBuilderInstance } from "./PunishActions/actions/Ban/BanActionBuilder";
import { DeleteMessageActionBuilderInstance } from "./PunishActions/actions/DeleteMessage/DeleteMessageActionBuilder";
import { DisabledActionBuilderInstance } from "./PunishActions/actions/Disabled/DisabledActionBuilder";
import { IncrementalActionBuilderInstance } from "./PunishActions/actions/Incremental/IncrementalActionBuilder";
import { KickActionBuilderInstance } from "./PunishActions/actions/Kick/KickActionBuilder";
import { MuteActionBuilderInstance } from "./PunishActions/actions/Mute/MuteActionBuilder";
import { MuteMediaActionBuilderInstance } from "./PunishActions/actions/MuteMedia/MuteMediaActionBuilder";
import { NotifyActionBuilderInstance } from "./PunishActions/actions/Notify/NotifyActionBuilder";
import { LogActionBuilderInstance } from "./PunishActions/actions/Log/LogActionBuilder";

import { keyBy } from "lodash";

export function getBasePunishActionBuilders<T extends BasePunishActionBuilder>(overrideBuilders?: Array<T>) {
  return keyBy([
    BanActionBuilderInstance,
    DeleteMessageActionBuilderInstance,
    DisabledActionBuilderInstance,
    IncrementalActionBuilderInstance,
    KickActionBuilderInstance,
    MuteActionBuilderInstance,
    MuteMediaActionBuilderInstance,
    NotifyActionBuilderInstance,
    LogActionBuilderInstance,
    ...overrideBuilders ?? []
  ], 'type')
}
