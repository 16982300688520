












































import PunishActionPreview from "../../actions/components/PunishActionPreview.vue";
import { getBasePunishActionBuilders } from "@/includes/logic/Modules/PunishActions/PunishActionsBuilderFactory";
import {
  PunishActionEditorButtonsKey,
  PunishActionEditorPlaceholdersKey,
  PunishActionEditorPrefix
} from "@/includes/logic/Modules/di";
import { createListItem } from "../helpers";
import { BasePunishActionModel, DisabledAction, PunishActionTypesEnum } from "../..";
import { errorNotification } from "@/includes/NotificationService";
import { BasePunishActionBuilder } from "@/includes/logic/Modules/PunishActions/PunishActionBuilder";

import {
  PlaceholdersOnes,
  PlaceholdersStack
} from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import { TagData } from "piramis-base-components/src/components/Tags/types";
import WizardLikeSelect from "piramis-base-components/src/components/WizardLikeSelect/WizardLikeSelect.vue";
import { IWizardLikeListItem, WizardLikeList } from "piramis-base-components/src/components/WizardLikeSelect/types";

import { Component, Prop, Provide, VModel } from 'vue-property-decorator';
import { cloneDeep, } from "lodash";
import Vue from "vue";

@Component({
  components: {
    WizardLikeSelect,
    PunishActionPreview,
  }
})
export default class PunishActionSetting extends Vue {
  @VModel() action!: BasePunishActionModel

  @Prop() punishActionTypes!: Array<PunishActionTypesEnum>

  @Prop() titleKey!: string

  @Prop() prefix!: string

  @Provide(PunishActionEditorPrefix) prefixProvide = this.prefix

  @Prop() tags!: Array<TagData> | null | undefined

  @Prop() messageEditorPlaceholders!: PlaceholdersOnes | PlaceholdersStack

  @Provide(PunishActionEditorPlaceholdersKey) placeholders = this.messageEditorPlaceholders

  @Prop() messageEditorButtons!: PlaceholdersOnes | PlaceholdersStack

  @Provide(PunishActionEditorButtonsKey) buttons = this.messageEditorButtons

  @Prop({ type: Array, default: () => [] }) customBuilders!: Array<BasePunishActionBuilder>

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  get disabledValue() {
    return (this.tags !== undefined && this.tags !== null) || this.disabled
  }

  get footerDisableButtonTitle() {
    const base = 'punish_action_modal_disable_button_title'

    if (this.prefix) {
      return this.$t(`${ this.prefix }${ base }`)
    }

    return this.$t(base)

  }

  punishActionsBuilders = this.punishActionTypes
    ? Object.entries(getBasePunishActionBuilders(this.customBuilders))
      .reduce<ReturnType<typeof getBasePunishActionBuilders>>((acc, [ key, value ]) => {

        if (this.punishActionTypes.includes(key as PunishActionTypesEnum)) {
          acc[key] = value
        }

        return acc
      }, {})
    : getBasePunishActionBuilders(this.customBuilders)

  currentActionType: BasePunishActionModel['type'] | null = null

  currentAction: BasePunishActionModel | null = null

  isModalActive = false

  setActionToNone() {
    this.action = new DisabledAction()
    this.isModalActive = false
  }

  onEditClick(action: BasePunishActionModel | undefined) {
    if (action) {
      if (action.type === PunishActionTypesEnum.Disabled) {
        this.isModalActive = true
      }

      this.currentActionType = action.type
    }
  }

  handleSelectItem(key: PunishActionTypesEnum | null) {
    if (key) {
      if (key === this.action.type) {
        this.currentAction = cloneDeep(this.action)
      } else {
        this.currentAction = this.punishActionsBuilders[key].getModel()

        if (key === PunishActionTypesEnum.Log) {
          this.setAction()
          this.isModalActive = false
        }
      }
    }
  }

  setAction() {
    if (this.currentAction) {
      this.action = this.currentAction

      this.currentAction = null
    }
  }

  async saveItem(_: IWizardLikeListItem['key'] | null, closeModalCallback: () => void) {
    if (this.currentAction) {
      try {
        const valid = await this.currentAction.validate()

        if (valid) {
          this.setAction()

          closeModalCallback()
        }
      } catch (e) {
        errorNotification(e)
      }
    }
  }

  get listData(): WizardLikeList {
    const items = Object.entries(this.punishActionsBuilders)
      .reduce<Partial<Record<PunishActionTypesEnum, Array<IWizardLikeListItem>>>>((acc, [ key, builder ]) => {
        acc[key as PunishActionTypesEnum] = [ createListItem(key, builder, this.$i18n) ]

        return acc
      }, {})

    return [
      {
        type: "Grouped",
        title: this.$t('punish_action_types_base_group_title').toString(),
        items: [
          ...items?.Ban ?? [],
          ...items?.Mute ?? [],
          ...items?.Kick ?? [],
        ]
      },
      {
        type: "Simple",
        items: [
          ...items?.Notify ?? [],
          ...items?.Log ?? [],
          ...items?.DeleteMessage ?? [],
          ...items?.MuteMedia ?? [],
          ...items?.Incremental ?? []
        ]
      }
    ]
  }
}
