import { BasePunishActionBuilder } from "@/includes/logic/Modules/PunishActions/PunishActionBuilder";
import { KickAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

export class KickActionBuilder extends BasePunishActionBuilder<KickAction> {
  type = PunishActionTypesEnum.Kick

  icon = 'logout'

  view = () => import('./KickActionView.vue');

  getModel(): KickAction {
    return plainToInstance(KickAction, {
      type: PunishActionTypesEnum.Kick,
      delete_message: true,
      delete_message_delay: 0,
      message: []
    });
  }
}

export const KickActionBuilderInstance= new KickActionBuilder()
