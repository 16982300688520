







































import CommonFilterSettings from "@/includes/logic/Modules/modules/filters/CommonFilterSettings";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { BRAND_TAG } from "@/includes/constants";
import { CommentsModeEnum } from "@/includes/logic/Modules/modules/filters/types";

import { SelectOption } from "piramis-base-components/src/logic/types";
import Accordion from "piramis-base-components/src/components/Accordion/Accordion.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator';

@Component({
  data() {
    return {
      CommentsModeEnum
    }
  },
  components: {
    Accordion,
    SelectInput,
    SwitchInput,
    NumberInput
  }
})
export default class FilterExtraSettings extends Mixins(InputSetups, TariffsTagsHelper) {
  @VModel() settings!: CommonFilterSettings

  @Prop({ type: Boolean, required: false, default: false }) ignoreCaption!: boolean

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  get tag() {
    const CommentsModeTag = this.getTagsByFieldKey('comments_mode')
    const hasCommentsModeInChatConfigOption = !this.getTagsByFieldKey('comments_mode')

    if (hasCommentsModeInChatConfigOption && !this.$store.state.chatState.chat?.brand) {
      return BRAND_TAG
    } else if (!hasCommentsModeInChatConfigOption) {
      return CommentsModeTag
    } else {
      return null
    }
  }

  commentsModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('comments_mode_options_none').toString(),
        value: CommentsModeEnum.None
      },
      {
        label: this.$t('comments_mode_options_comments').toString(),
        value: CommentsModeEnum.Comments
      },
      {
        label: this.$t('comments_mode_options_group_messages').toString(),
        value: CommentsModeEnum.GroupMessages
      },
      {
        label: this.$t('comments_mode_options_comments_not_members').toString(),
        value: CommentsModeEnum.CommentsNotMembers
      },
      {
        label: this.$t('comments_mode_options_comments_members').toString(),
        value: CommentsModeEnum.CommentsMembers
      }
    ]
  }
}
