import { KeysOfType } from "@/includes/types/util.types";
import CommonFilterSettings from "./CommonFilterSettings";
import { BasePunishActionModel } from "../../PunishActions/PunishActions";
import { BasePunishActionBuilder } from "@/includes/logic/Modules/PunishActions/PunishActionBuilder";

export enum CommentsModeEnum {
  'None' = 'None',
  'Comments' = 'Comments',
  'GroupMessages' = 'GroupMessages',
  'CommentsNotMembers' = 'CommentsNotMembers',
  'CommentsMembers' = 'CommentsMembers',
}

type CommonSettingKeys = KeysOfType<CommonFilterSettings, BasePunishActionModel>

export type CustomPunishBuilders = Partial<Record<CommonSettingKeys, Array<BasePunishActionBuilder>>>
