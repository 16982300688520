import { BasePunishActionBuilder } from "@/includes/logic/Modules/PunishActions/PunishActionBuilder";
import { MuteAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

export class MuteActionBuilder extends BasePunishActionBuilder<MuteAction> {
  type = PunishActionTypesEnum.Mute

  icon = 'volume_off'

  view = () => import('./MuteActionView.vue');

  getModel(): MuteAction {
    return plainToInstance(MuteAction, {
      type: PunishActionTypesEnum.Mute,
      delete_message: true,
      delete_message_delay: 0,
      time: 600,
      message: []
    })
  }
}

export const MuteActionBuilderInstance= new MuteActionBuilder()
