











































































import CommonFilterSettings from "@/includes/logic/Modules/modules/filters/CommonFilterSettings";
import { InputSetups } from "@/mixins/input-setups";
import Placeholders from "@/mixins/placeholders/placeholders";
import PunishActionSetting
  from "@/includes/logic/Modules/PunishActions/PunishActions/actions/components/PunishActionSetting.vue";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';
import { CustomPunishBuilders } from "@/includes/logic/Modules/modules/filters/types";

@Component({
  components: {
    NestedContent,
    PunishActionSetting,
    SwitchInput,
    TimeUnitInput,
    NumberInput,
  }
})
export default class FilterRemoveEditedSetting extends Mixins(InputSetups, Placeholders) {
  @VModel() settings!: CommonFilterSettings

  @Prop({ type: Object, default: null }) customPunishBuilders!: CustomPunishBuilders
}
